$dot: #349890;
$ring: rgba(103, 203, 195, 0.5);
$delay: 1ms;
$ring-size: 1.5rem;
$ring-size-hover: 2rem;

.ring {
	position: fixed;
	top: 0;
	left: 0;
	width: $ring-size;
	height: $ring-size;
	background-color: $ring;
	border-radius: 100%;
	transform: translate(-50%, -50%);
	-webkit-transition-duration: $delay;
	transition-duration: $delay;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	will-change: width, height, transform, border;
	z-index: 9999999;
	pointer-events: none;
}

.dot {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 8px;
	height: 8px;
	background-color: $dot;
	border-radius: 100%;
	transform: translate(-50%, -50%);
	z-index: 9999999;
	pointer-events: none;
}

.ring.hovered {
	width: $ring-size-hover;
	height: $ring-size-hover;
	border-width: 3px;
	background-color: $ring;
}

.dot.hovered {
	display: none;
}

@media screen and (max-width: 600px) {
	.dot,
	.ring {
		visibility: hidden;
		display: none;
	}
}
