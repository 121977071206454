.portfolio-navbar {
	display: flex;
	justify-content: space-between !important;
}

.aws-btn.aws-btn--small.aws-btn--visible {
	width: fit-content;
}

.navBarLinksContainer {
	flex-direction: row !important;
	justify-content: space-evenly !important;
}

.navbarSocialIconsContainer {
	flex-direction: row !important;
	justify-content: space-evenly !important;
}

.navbarLinks {
	width: fit-content;
	margin: 0.5rem;
	white-space: nowrap;
	color: #fafafa !important;
	font-size: larger;
	font-weight: bold;
}

.navbarSocialIcons {
	margin-right: 1rem;
}

#offcanvasNavbar {
	width: fit-content;
}

.offcanvasBody {
	background-color: var(--navbar-bg-color);
}

.navbar-toggle {
	border-color: var(--navbar-bg-color) !important;
}

.offCanvasNavItem {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

/* for the stackoverflow offcanvas button underlying border */
#offcanvasNavbar .stackOverflowButton .aws-btn__wrapper::before {
	background-color: #f48225;
	filter: brightness(70%);
}
/* for the stackoverflow navbar button underlying border */
.portfolio-navbar .stackOverflowButton .aws-btn__wrapper::before {
	background-color: #f48225;
	filter: brightness(70%);
}
#offcanvasNavbar .stackOverflowButton .aws-btn__content {
	border: 2px solid #fafafa;
}
.portfolio-navbar .stackOverflowButton .aws-btn__content {
	border: 2px solid #fafafa;
}

.portfolio-navbar
	.stackOverflowButton
	.aws-btn__wrapper
	.fa-stack-overflow::before {
	color: #f48225;
}

.verticalLine {
	border-left: 0.1rem solid #2B3C4C;
	padding-left: 1rem;
}
