.portfolioContainer {
	background-color: #e7ecf0;
	height: 100%;
	min-height: 100vh;
	/* the height and min-height combo is super important for the rendering of the background color */
}

.portfolioContainer.dark {
	background-color: #010409 !important;
}
