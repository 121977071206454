@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
	font-family: '72fallback', '72', '72full', Arial, Helvetica, sans-serif;
	font-size: 1rem;
	cursor: none !important;
}

html,
body,
#root {
    height: 100vh!important;
}

[role='button'] {
	cursor: none !important;
}

:root {
	--main-bg-color: #2e2e2e;
	--navbar-bg-color: #3f5161;
}

#portfolio-navbar {
	background-color: var(--navbar-bg-color) !important;
	padding: 0rem !important;
	margin-bottom: 0.5rem;
}
