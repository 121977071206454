@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

* {
	font-family: '72fallback', '72', '72full', Arial, Helvetica, sans-serif;
	font-size: 1rem;
	cursor: none !important;
}

html,
body,
#root {
    height: 100vh!important;
}

[role='button'] {
	cursor: none !important;
}

:root {
	--main-bg-color: #2e2e2e;
	--navbar-bg-color: #3f5161;
}

#portfolio-navbar {
	background-color: #3f5161 !important;
	background-color: var(--navbar-bg-color) !important;
	padding: 0rem !important;
	margin-bottom: 0.5rem;
}

.portfolio-navbar {
	display: flex;
	justify-content: space-between !important;
}

.aws-btn.aws-btn--small.aws-btn--visible {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.navBarLinksContainer {
	flex-direction: row !important;
	justify-content: space-evenly !important;
}

.navbarSocialIconsContainer {
	flex-direction: row !important;
	justify-content: space-evenly !important;
}

.navbarLinks {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin: 0.5rem;
	white-space: nowrap;
	color: #fafafa !important;
	font-size: larger;
	font-weight: bold;
}

.navbarSocialIcons {
	margin-right: 1rem;
}

#offcanvasNavbar {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.offcanvasBody {
	background-color: var(--navbar-bg-color);
}

.navbar-toggle {
	border-color: var(--navbar-bg-color) !important;
}

.offCanvasNavItem {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

/* for the stackoverflow offcanvas button underlying border */
#offcanvasNavbar .stackOverflowButton .aws-btn__wrapper::before {
	background-color: #f48225;
	-webkit-filter: brightness(70%);
	        filter: brightness(70%);
}
/* for the stackoverflow navbar button underlying border */
.portfolio-navbar .stackOverflowButton .aws-btn__wrapper::before {
	background-color: #f48225;
	-webkit-filter: brightness(70%);
	        filter: brightness(70%);
}
#offcanvasNavbar .stackOverflowButton .aws-btn__content {
	border: 2px solid #fafafa;
}
.portfolio-navbar .stackOverflowButton .aws-btn__content {
	border: 2px solid #fafafa;
}

.portfolio-navbar
	.stackOverflowButton
	.aws-btn__wrapper
	.fa-stack-overflow::before {
	color: #f48225;
}

.verticalLine {
	border-left: 0.1rem solid #2B3C4C;
	padding-left: 1rem;
}

.headerImage {
	padding: 0.5rem !important;
}

.overviewCard {
	border-radius: 1rem!important;
}

.experienceCard {
	margin-bottom: 1rem;
	border: 0rem!important;
}

.SAPlogo {
	margin-right: 0.5rem;
	width: 5rem;
	height: auto;
}

.PitneyLogo {
	margin-right: 0.5rem;
	width: 8rem;
}

.companyHeader {
	font-weight: bold;
}

.dateHeader {
	font-weight: bold;
}

.bg-warning.card-color.dark {
	background-color: #21262d !important;
	color: white;
	opacity: 0.9 !important;
}
.bg-warning.card-color {
	background-color: white !important;
	color: black;
	opacity: 0.9 !important;
}

.fas.fa-download {
	margin-right: 0.5rem;
}


.mobile-image {
	width: inherit;
	height: inherit;
}

/* to move the position indicator up so that the image can be seen */
.carousel-indicators {
	position: relative !important;
}

.carousel-control-prev {
	cursor: none;
}
.carousel-control-next {
	cursor: none;
}
.contentCard {
	-webkit-animation: bounceIn 0.5s;
	        animation: bounceIn 0.5s;
}

@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-webkit-keyframes bounceIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.1);
		        transform: scale(0.1);
	}
	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}
@keyframes bounceIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.1);
		        transform: scale(0.1);
	}
	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

.portfolioContainer {
	background-color: #e7ecf0;
	height: 100%;
	min-height: 100vh;
	/* the height and min-height combo is super important for the rendering of the background color */
}

.portfolioContainer.dark {
	background-color: #010409 !important;
}

.ring{position:fixed;top:0;left:0;width:1.5rem;height:1.5rem;background-color:rgba(103,203,195,0.5);border-radius:100%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);transition-duration:1ms;transition-timing-function:ease-out;will-change:width, height, transform, border;z-index:9999999;pointer-events:none}.dot{position:fixed;top:50%;left:50%;width:8px;height:8px;background-color:#349890;border-radius:100%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);z-index:9999999;pointer-events:none}.ring.hovered{width:2rem;height:2rem;border-width:3px;background-color:rgba(103,203,195,0.5)}.dot.hovered{display:none}@media screen and (max-width: 600px){.dot,.ring{visibility:hidden;display:none}}

