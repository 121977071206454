.experienceCard {
	margin-bottom: 1rem;
	border: 0rem!important;
}

.SAPlogo {
	margin-right: 0.5rem;
	width: 5rem;
	height: auto;
}

.PitneyLogo {
	margin-right: 0.5rem;
	width: 8rem;
}

.companyHeader {
	font-weight: bold;
}

.dateHeader {
	font-weight: bold;
}

.bg-warning.card-color.dark {
	background-color: #21262d !important;
	color: white;
	opacity: 0.9 !important;
}
.bg-warning.card-color {
	background-color: white !important;
	color: black;
	opacity: 0.9 !important;
}

.fas.fa-download {
	margin-right: 0.5rem;
}

