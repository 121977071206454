.mobile-image {
	width: inherit;
	height: inherit;
}

/* to move the position indicator up so that the image can be seen */
.carousel-indicators {
	position: relative !important;
}

.carousel-control-prev {
	cursor: none;
}
.carousel-control-next {
	cursor: none;
}