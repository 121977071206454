.contentCard {
	animation: bounceIn 0.5s;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes bounceIn {
	0% {
		opacity: 0;
		transform: scale(0.1);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
